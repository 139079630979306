<template>
  <div>
    
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            オペレーターのダッシュボード
            <CSwitch
              class="float-right"
              size="sm"
              shape="pill"
              color="info"
              data-on="On"
              data-off="Off"
              :checked="true"
            />
          </CCardHeader>
          <CCardBody>
            オペレーターに向け、宿の基本情報・ステータス・予約一覧・タグと検索情報を示すこと。
            <div class="bd-example">
              <li class="h6">管理している宿のリストを示すこと</li>
              <li class="h6">管理している宿のステータス・予約一覧等を示すこと</li>
              <li class="h6">宿と関連しているタグ、検索情報を示すこと</li>
              <li class="h6">その他</li>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- TODO: -->
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "Dashboard",
  data() {
    return {
      loading: false,
    };
  },
  computed: {

  },
  methods: {

  },
  beforeMount() {
    console.log(this.$store.state.uniqueId);
  },
};
</script>
